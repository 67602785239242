import React from 'react'
import { useTranslation } from 'react-i18next'

import { Layout } from '../components/layouts/main-layout'

const NotFoundPage = () => {
  const { t } = useTranslation()
  return (
    <Layout pageName={t('error.404')}>
      <h1>NOT FOUND</h1>
      <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
    </Layout>
  )
}

export default NotFoundPage
